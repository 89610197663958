// @flow

import React from 'react';
// import { Link } from 'react-router-dom';
// import * as Scroll from 'react-scroll';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

// import Button from '../../components/Button';
import Section from '../../components/Section';
import styles from './home.module.scss';

// $FlowFixMe
import Programming from '../../assets/svg/programming.svg';
import metaValues from '../../../config';
import translation from '../../translate';
import employmentHistory from './_history';
import stackInfo from './_stack';

// const scroll = Scroll.animateScroll;
const renderAreas = (responsibilities: Array<string>): any => {
  if (responsibilities.length > 0) {
    return (
      <div className={styles.responsibilities}>
        <h4>Responsibilities:</h4>
        <ul>
          {responsibilities.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
    );
  }

  return null;
};

const renderTehnologies = (tehnologies: Array<string>): any => {
  if (tehnologies.length > 0) {
    return (
      <div className={styles.tehnologies}>
        <h4>Technologies:</h4>
        <ul>
          {tehnologies.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
    );
  }

  return null;
};

const HomeScreen = (): any => {
  const { defaultTitle } = metaValues;
  const { language } = useSelector(({ common }: any) => common);
  const title = `${translation('_SEO_TITLE1', language)} - ${defaultTitle}`;
  const list = translation('_HOME_LIST', language);

  return (
    <section>
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content={translation('_SEO_TITLE2', language)}
        />
        <meta
          property="keywords"
          content={translation('_SEO_KEYWORDS', language)}
        />
        <meta property="og:title" content={title} />
        <meta
          property="og:image"
          content={`${metaValues.domain}/public/javascript.svg`}
        />
      </Helmet>

      <Section>
        <div className={styles.content}>
          <h1>{translation('_HOME_TITLE1', language)}</h1>
          <h3>{translation('_HOME_TITLE2', language)}</h3>
          <img
            className={styles.homeImage}
            src={Programming}
            alt="Programming"
          />
          <p>{translation('_HOME_TEXT1', language)}</p>

          <ul>
            {list.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>

          <div className={styles.stack}>
            {stackInfo.map(item => {
              const { title: stackTitle, items } = item;

              return (
                <div className={styles.stackItem} key={stackTitle}>
                  <h3>{stackTitle}</h3>
                  <ul>
                    {items.map((one, index) => (
                      <li key={index}>{one}</li>
                    ))}
                  </ul>
                </div>
              );
            })}
          </div>

          <div className={styles.dotlist}>
            <h3>Employment history:</h3>
            <ul className={styles.timeline}>
              {employmentHistory.map(item => {
                const {
                  date,
                  company,
                  title: itemTitle,
                  description,
                  employment,
                  responsibilities,
                  tehnologies
                } = item;

                const iconName = `cbp_tmicon_${item.icon}`;
                const iconStyle = styles[iconName];

                return (
                  <li key={item}>
                    <time className={styles.cbp_tmtime}>
                      <span>{company}</span>
                      <span>{employment}</span>
                      <span>{date}</span>
                    </time>
                    <div className={`${styles.cbp_tmicon} ${iconStyle}`} />
                    <div className={styles.cbp_tmlabel}>
                      <h2>{itemTitle}</h2>
                      {description.map((line, index) => (
                        <p key={index}>{line}</p>
                      ))}
                      {renderTehnologies(tehnologies)}
                      {renderAreas(responsibilities)}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </Section>
    </section>
  );
};

export default HomeScreen;
