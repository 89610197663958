const translate = {
  _HOME_CONTACT: 'Kontaktiere mich',
  _SEO_TITLE1: 'Persönliche Lebenslauf-Website, Kylypko Grygorii',
  _SEO_TITLE2: `Ich biete den gesamten Zyklus von Node.js/React.js-Entwicklungsdiensten an, um Unternehmen und Startups bei der Umsetzung ihrer Traumprojekte zu unterstützen.`,
  _SEO_KEYWORDS: `Entwicklung reagieren, NodeJS-Entwickler einstellen, NodeJS-Entwickler, NodeJS-Teamleiter`,
  _HOME_TITLE1: `Kylypko Grygorii`,
  _HOME_TITLE2: `Team/Tech-Leiter, Senior Full Stack Developer`,
  _HOME_TEXT1: `Ich bin ein Fullstack-Ingenieur mit mehr als 15 Jahren Erfahrung in der Entwicklung innovativer Lösungen und der Bereitstellung hochwertiger Software.“ Mein Fachwissen liegt in der Architektur und Entwicklung leistungsstarker Softwareprodukte und ich setze mich intensiv dafür ein, Innovationen voranzutreiben, Unternehmensziele zu erreichen und ein solides Verständnis für neue Technologien zu bewahren. Zu meinen Fähigkeiten gehören umfassende Kenntnisse in Node.js, React.js, JavaScript, MongoDB und PostgreSQL. Ich bemühe mich um ein fundiertes Verständnis von Datenstrukturen, damit jedes Projekt komplexe Herausforderungen effektiv bewältigen kann.`,
  _HOME_LIST: [
    `Über 9 Jahre Erfahrung in der kommerziellen Entwicklung von Softwarelösungen mit Schwerpunkt auf Node.js (Backend-Teil).`,
    `Erfolgsbilanz zunehmender Verantwortung in den Bereichen Softwaredesign, Systemanalyse, Entwicklung, Designbereitstellungsstrategien und Projektentwicklung über den gesamten Lebenszyklus.`,
    `Starke Frontend- und Backend-weite Fähigkeiten (Node.js, React.js, JavaScript, MongoDB, PostgreSQL usw.)`,
    `Umfassende praktische Erfahrung im Design von Systemarchitekturen (Videostreaming, IoT-Netzwerk, Bankabrechnungen und Integrationen von Drittanbietern).`,
    `Praktische Erfahrung in allen Phasen der Systementwicklung, einschließlich Anforderungsdefinition, Design, Architektur, Tests und Support.`,
    `Erfahrung im Schreiben von Code, Debugging, Fehlerbehebung, Anforderungsanalyse, Implementierung von Funktionen, Integrationsschritten und User Stories (UX/UI-Seite).`,
    `Gutes logisches Denken, schnelles Erlernen neuer Fähigkeiten, Selbstständigkeit, Selbstlernfähigkeit, hohes Maß an Verantwortung.`,
    `Verantwortungsbewusst, fleißig, ergebnisorientiert, kreativ und kommunikativ, Teamplayer.`,
    `Gute Liebe zum Detail. Schneller Lerner.`,
    `Arbeiten Sie lieber mit der TDD-Methodik und versuchen Sie, alle Produktionsprobleme auf 0 zu reduzieren`,
    `Ausgeprägte Problemlösungs-, Kommunikations- und Zeitmanagementfähigkeiten.`,
    `Fähigkeiten zur Technologiebewertung und Analyse von Geschäftsanforderungen.`
  ]
};

export default translate;
