// @flow
import * as React from 'react';
// import { Link } from 'react-router-dom';

import styles from './footer.module.scss';

const Footer = (): any => {
  const heart = (
    <span role="img" className={styles.emojii} aria-label="Heart">
      ❤️
    </span>
  );

  return (
    <div className={styles.footer}>
      <div className={styles.content}>
        <ul className={styles.grid}>
          <ol>
            <h3>Services:</h3>
            <li>E-mobility solutions</li>
            <li>Custom software development</li>
            <li>Web Development</li>
            <li>Mobile development</li>
          </ol>
        </ul>
        <div className={styles.title}>
          Made with
          {heart}
          &nbsp;in&nbsp;
          <a
            onClick={() => window.open('https://www.reactapp.top', '_blank')}
            target="_blank"
            rel="noopener noreferrer"
          >
            Kharkiv
          </a>
          , Ukraine &copy; 2023
        </div>
      </div>
    </div>
  );
};

export default Footer;
