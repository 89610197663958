const data = [
  {
    title: 'Preffered stack',
    items: ['NodeJS', 'Javascript', 'ReactJS']
  },
  {
    title: 'Technologies and Libraries',
    items: [
      'Microservices',
      'Node.js',
      'React+Redux',
      'React Native',
      'JavaScript',
      'AWS (EC2, Lambda, DynamoDB, S3, SQS, SNS)',
      'AZURE',
      'K8',
      'GraphQL',
      'Microservices architecture',
      'ElasticSearch',
      'Kibana',
      'Prometheus',
      'Grafana',
      'Memcache',
      'Sequelize',
      'Electon',
      'Docker',
      'Express.js',
      'Webpack',
      'Jest',
      'Selenium',
      'Selendroid',
      'Appium',
      'IOS (backend API node.js/php)',
      'Google+Yandex map & etc.',
      'CSS/JS/HTML',
      'SASS/LESS'
    ]
  },
  {
    title: 'Database Systems',
    items: [
      'MS SQL Server',
      'PostgreSQL',
      'ElasticSearch',
      'MySQL',
      'SQLite',
      'MongoDB'
    ]
  },
  {
    title: 'Additional skills',
    items: [
      'Raspberry PI development',
      'OCPP protocol customization',
      'IoT developments',
      'POS devices (printers, MSR, barcode, terminals and etc)',
      'third parties API integration (Stripe, Paymentology, Cryptocurrency wallets, Facebook, VK, Twitter, Instagram)'
    ]
  },
  {
    title: 'Hobbies',
    items: [
      'EV charging and everything related with EV Cars',
      'Pet project with OCPP Charging billing - System UI + Backend + Websockets'
    ]
  },
  {
    title: 'Pet projects',
    items: ['www.reactapp.top', 'www.ev-ua.net', 'www.otdih.pro']
  }
];

export default data;
