const translate = {
  _HOME_CONTACT: `Зв'язатися зі мною`,
  _SEO_TITLE1: 'Персональний сайт CV, Килипко Григорій',
  _SEO_TITLE2:
    'Я надаю повний цикл послуг з розробки Node.js/React.js, щоб допомогти компаніям і стартапам створювати проекти своєї мрії.',
  _SEO_KEYWORDS:
    'react development, найняти розробника nodejs, розробника nodejs, керівника команди nodejs',
  _HOME_TITLE1: `Килипко Григорій`,
  _HOME_TITLE2: `Team/Tech Lead, Senior Full Stack Developer`,
  _HOME_TEXT1: `Я інженер Fullstack із 15+ роками досвіду розробки інноваційних рішень і надання високоякісного програмного забезпечення. Мій досвід полягає в архітектурі та розробці високопродуктивних програмних продуктів, і я глибоко відданий просуванню інновацій, досягненню цілей компанії та підтриманню міцного розуміння нових технологій. Мій набір навичок включає широке знання Node.js, React.js, JavaScript, MongoDB і PostgreSQL. Я намагаюся чітко зрозуміти структури даних, що дозволяє будь-якому проекту ефективно вирішувати складні завдання.`,
  _HOME_LIST: [
    `9+ років досвіду комерційної розробки програмних рішень з основним фокусом на Node.js (серверна частина).`,
    `Послужний список підвищення відповідальності за проектування програмного забезпечення, системний аналіз, розробку, стратегії розгортання дизайну та розробку проектів повного життєвого циклу.`,
    `Сильний набір навичок інтерфейсу та бекенда (Node.js, React.js, JavaScript, MongoDB, PostgreSQL тощо)`,
    `Великий практичний досвід у розробці архітектури системи (потокове відео, мережа iOT, банківські рахунки та сторонні інтеграції).`,
    `Практичний досвід на всіх етапах розробки системи, включаючи визначення вимог, проектування, архітектуру, тестування та підтримку.`,
    `Досвід написання коду, налагодження, виправлення помилок, аналізу вимог, реалізації функцій, етапів інтеграції та історій користувачів (сторона UX/UI).`,
    `Гарне логічне мислення, швидке засвоєння нових навичок, самостійність, самонавчання, високий рівень відповідальності.`,
    `Відповідальний, працьовитий, націлений на результат, творчий та комунікабельний, командний гравець`,
    `Гарна увага до деталей. Швидко навчаюсь.`,
    `Віддаю перевагу роботі з методологією TDD і намагаюся звести будь-які проблеми продакшена до нуля`,
    `Сильні навички вирішення проблем, спілкування та управління часом`,
    `Навички оцінки технологій та аналізу бізнес-вимог.`
  ]
};

export default translate;
