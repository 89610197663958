const translate = {
  _HOME_CONTACT: 'Contact me',
  _SEO_TITLE1: 'Personal CV website, Kylypko Grygorii',
  _SEO_TITLE2:
    'I`m providing full cycle of Node.js/React.js development services to help companies and startups create their dream projects.',
  _SEO_KEYWORDS:
    'react development, hire nodejs developer, nodejs developer, nodejs team lead',
  _HOME_TITLE1: `Kylypko Grygorii`,
  _HOME_TITLE2: `Team/Tech Lead, Senior Full Stack Developer`,
  _HOME_TEXT1: `I'm a Fullstack Engineer with 15+ years of experience crafting innovative solutions and delivering high-quality software. My expertise lies in architecting and developing high-performance software products, and I'm is deeply committed to driving innovation, achieving company goals, and maintaining a solid grasp of emerging technologies. My skill set includes extensive proficiency in Node.js, React.js, JavaScript, MongoDB, and PostgreSQL. I'm trying robust understanding of data structures, enabling any project to tackle complex challenges effectively.`,
  _HOME_LIST: [
    `9+ years of experience in commercial development of software solutions with primary focus on Node.js (backend part).`,
    `Track record of increasing responsibility in software design, systems analysis, development, design deployment strategies and full lifecycle project development.`,
    `Strong frontend and backend wide skill set (Node.js, React.js, JavaScript, MongoDB, PostgreSQL, etc.)`,
    `Strong practical experience in design of system architecture (Videostreaming, iOT network, Bank billings and 3rd party integrations).`,
    `Hands-on experience in all stages of system development efforts, including requirements definition, design, architecture, testing, and support.`,
    `Experienced in writing code, debugging, bug fixing, requirement analyzing, implementing features, integrations steps and user stories (UX/UI side).`,
    `Good logical thinking, quick taking of new skills, self- dependence, self-learning, high level of responsibility.`,
    `Responsible, hard-working, result-oriented, creative and communicable, team player.`,
    `Good attention to details. Fast learner.`,
    `Prefer to work with TDD methodology and trying to reduce any production issues to 0`,
    `Strong problem-solving, communication, and time management skills.`,
    `Technology evaluation and business requirements analysis skills.`
  ]
};

export default translate;
