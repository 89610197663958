const history = [
  {
    dateTime: '2013-04-11 12:04',
    date: '2022',
    icon: 'earth',
    title: 'Team-Lead, Senior Full-stack Software Engineer',
    company: 'NOMO Bank, GB',
    employment: 'contract',
    description: [
      'Faster, easier and safer payments at the tips of your fingers. Spend in-store, online and in apps using your Apple devices and discover a whole new way to pay. Mobile application for transfer money and payment operations around the world.'
    ],
    tehnologies: [
      'JavaScript',
      'NodeJS',
      'ReactJS',
      'React Native',
      'Jest',
      'Redis',
      'Docker',
      'DynamoDB',
      'AWS Lambdas'
    ],
    responsibilities: [
      'Design and architecture of DB storages for stored content (DynamoDB and migration to  PostgreSQL databases)',
      'Work with the product owner - collecting, clarify, and movement requirements to tasks in JIRA',
      'Code refactoring and performance optimization',
      'AGILE process (plannings and groomings)',
      'Leading team of developers involved in current sub-team, mentoring',
      'Code review'
    ]
  },

  {
    dateTime: '2013-04-11 12:04',
    date: '2019 2022',
    icon: 'earth',
    title: 'Team-Lead, Senior Full-stack Software Engineer',
    company: 'PLUTO.TV, USA',
    employment: 'contract',
    description: [
      'Pluto TV, a Paramount (ex. Viacom VCBS) company, is the leading free streaming television service in America, delivering 100+ live and original channels and thousands of on-demand movies in partnership with major TV networks, movie studios, publishers, and digital media companies. Pluto TV is available on all mobile, web and connected TV streaming devices and millions of viewers tune in each month to watch premium news, TV shows, movies, sports, lifestyle, and trending digital series. Headquartered in West Hollywood, Pluto TV has offices in New York, Silicon Valley, Chicago and Berlin.'
    ],
    tehnologies: [
      'JavaScript',
      'NodeJS',
      'ReactJS',
      'Webpack',
      'Jest',
      'AWS',
      'Redis',
      'Docker',
      'PostgreSQL',
      'MongoDB'
    ],
    responsibilities: [
      'Responsibility areas: leading in areas of distribution feeds (Apple, Google, Samsung, LG, Vizion, Gracenote and etc), dashboard development and planning for new features with Product Owners (feeds, dashboard and API’s)',
      'Implementations for Content feed admin dashboard and iterations with partners (Google, Roku, TiVO, Vizio, Samsung, Apple, Gracenote, Comcast, FireTV), like draft versions, change requests, metadata design and views and etc',
      'Development of publishing admin dashboard (content and asset management system of all content inside the project, implementing architecture, microservices and development roadmap for front-end subprojects, mainly it’s dashboards with statistics and analytic information, KPI, metrics and etc)',
      'Design and architecture of DB storages for content (MongoDB + PostgreSQL databases)',
      'Working with the product owner - collecting, clarify, and movement requirements to tasks in JIRA',
      'Responsible for core components implementation and architecture includes microservices',
      'Code refactoring and performance optimization',
      'AGILE process (plannings and groomings)',
      'Leading team of developers involved in current sub-team, mentoring',
      'Code review',
      'Code coverage (integration and unit tests till my leadership on the project was increased to 98%)'
    ]
  },

  {
    dateTime: '2013-04-11 12:04',
    date: '2017',
    icon: 'screen',
    title: 'Team-Lead, Senior Full-stack Software Engineer',
    company: 'H&M, Berlin, Germany',
    employment: 'contract',
    description: [
      'Digital Brand Department. As one of the project, Itsapark - is a multi-brand platform for fashion and lifestyle and we encourage our users to click what they love, read what they want and only buy what they need. We hand-pick better buys and connect people with hot products and brands via inspiring content — from sustainable options and pre-loved pieces to life-long investments. Just the good stuff.',
      'Implementing new Digital Brand initiatives like NFT, Events and Activities in different countries and regions (USA, Japan and Europe), related with Brand promotion and new approaches in Online Shopping Culture.'
    ],
    tehnologies: [
      'JavaScript',
      'NodeJS',
      'Azure Functions',
      'Apollo GraphQL',
      'Typescript',
      'Algolia search engine',
      'Azure services (cognitive translations, pipelines and etc)'
    ],
    responsibilities: [
      'Front-end and back-end development, with an accent on backend tasks',
      'Responsibility areas: implementing backend side of whole project, microservices, GraphQL API and data exchange between connected stores and 3rd parties API’s',
      'Responsible for core components implementation and architecture includes microservices for React UI implementation',
      'Scrapping system design and implementation (scrapping products from market-places and price analysis for brands placed on our platform)',
      'Data Warehouse design and implementation (data aggregation from different sources and data analysis)'
    ]
  },

  {
    dateTime: '2013-04-11 12:04',
    date: '2017',
    icon: 'screen',
    title: 'Senior Fullstack Software Engineer',
    company: 'Astrum LLC, USA',
    employment: 'contract',
    description: [
      'A Machine Learning company focused on quantifying the value of Higher Education. AstrumU’s purpose is to help students find meaningful careers in which they can excel. They’re doing this through leveraging machine learning to drive transparency and efficiencies into the marketplace for the benefit of students, universities, and companies.',
      'Astrum is one of several up-and-coming companies using technology to match employers with recent grads. The company uses machine learning and predictive analytics to help students find impactful careers.',
      'Its end customers are large corporations that are trying to find talented employees.'
    ],
    tehnologies: [
      'JavaScript',
      'NodeJS',
      'ReactJS',
      'Redux',
      'SCSS',
      'CSS3',
      'Babel',
      'Webpack',
      'Jest',
      'Mocha/Chai',
      'K8S',
      'GraphQL',
      'Redis',
      'Docker',
      'PostgreSQL',
      'ElasticSearch'
    ],
    responsibilities: [
      'Front-end and back-end development, with an accent on backend tasks',
      'Responsibility areas: implementing backend side of whole project, microservices, GraphQL API and data exchange between connected stores and 3rd parties API’s',
      'Responsible for core components implementation and architecture includes microservices for React UI implementation',
      'Scrapping system design and implementation (scrapping products from market-places and price analysis for brands placed on our platform)'
    ]
  },

  {
    dateTime: '2013-04-11 12:04',
    date: '2017',
    icon: 'screen',
    title: 'Senior Fullstack Software Engineer',
    company: 'IDEX Exchange',
    employment: 'fix term project',
    description: [
      'IDEX Exchange (https://idex.market) - IDEX is a decentralized exchange based on Ethereum, featuring ERC20 tokens and Ethereum trading pairs. The platform uses smart contracts that give users the opportunity to manage their private keys, allowing them to trade in a secure peer-to-peer environment. As safer alternatives for fund management, IDEX decentralized exchange features Meta Mask wallet integrations and Ledger Nano S, which are preferable to entering user private keys manually.',
      'IDEX works by leveraging its smart contracts to manage its users’ transactions, unlike other decentralized exchanges. It allows a user to sign off a transaction with their private key but will broadcast the transaction to the Ethereum blockchain itself. In this way, it is able to update account balances and order books in a timely manner. This ensures reduced friction as it allows proper management of the flow of network transactions, providing a centralized exchange user experience without compromising the security and user control of a decentralized exchange.',
      'Part-time employment - Senior Full-stack Software Engineer. Refactoring for backend part, implementation for integration and unit testing, reduce codebase and optimization performance for blocks operations in scope of ETH operations, UI optimizations.'
    ],
    tehnologies: [
      'JavaScript',
      'TypeScript',
      'ReactJS',
      'Redux',
      'SCSS',
      'HTML5',
      'CSS3',
      'NodeJS',
      'Babel',
      'Webpack',
      'Jasmine/Jest'
    ],
    responsibilities: [
      'Refactoring for backend part',
      'Implementation for integration and unit testing',
      'Reduce codebase and optimization performance for blocks operations in the scope of ETH operations',
      'UI optimizations and refactoring',
      'CI/CD implementation and support of automatic multislot deployments'
    ]
  },
  {
    dateTime: '2013-04-11 12:04',
    date: '07/2015 11/2016',
    icon: 'screen',
    title: 'Senior Backend Engineer',
    company: 'Bosch Global Inc GmbH',
    employment: 'fix term project',
    description: [
      'Part-time employment - Bosch Auth Server it’s a global authorization system for Bosch intranet consumers (Bosch affiliate companies located at the whole word) to authorize in internal resources, RBAC and security verification, notifications and subscribing on all notification in corporate channels (slack, emails, chats, sms).'
    ],
    tehnologies: [
      'JavaScript',
      'Docker',
      'NodeJS',
      'Mocha/Chai',
      'Supertest',
      'SlackAPI',
      'Skype API',
      '3rd party API integrations'
    ],
    responsibilities: [
      'Design, architecture and deployment structure and approaches',
      'Implementation of global authorization system for Bosch intranet consumers',
      'RBAC and security verification',
      'SSL protection for all auth channels',
      'External channels posting information about abnormal activities with auth endpoint',
      'Extended logging for all activities and messaging about strange activities',
      'Notifications and subscribing on all notification in corporate channels (slack, emails, chats, sms)'
    ]
  },
  {
    dateTime: '2013-04-11 12:04',
    date: '2015 2017',
    icon: 'screen',
    title: 'Node.js Engineer, Lead developer',
    company: 'Heartland Commerce',
    employment: 'fulltime (remote)',
    description: [
      'XENIAL - Xenial is enterprise restaurant and managed food service focused. Its technology is installed in 51,000 locations, hosting 110,000 merchants in the cloud and supporting operations in 62 countries.',
      'They also have 30 years of experience providing five different POS systems to all sorts of restaurants and retail businesses. Once really smart mobile devices came along, and cloud computing got real, they decided to take full advantage of everything these technologies had to offer.'
    ],
    tehnologies: [
      'AWS',
      'Lambdas',
      'ExpressJS',
      'JavaScript',
      'NodeJS',
      'Mongo',
      'ReactJS',
      'SCSS',
      'HTML5',
      'CSS3',
      'Webpack',
      'Jasmine/Jest',
      'Docker'
    ],
    responsibilities: [
      'Analysis, design and development of user analysis solutions',
      'Implementation algorithm for bot detections',
      'Front-end and back-end part of the project'
    ]
  },
  {
    dateTime: '2013-04-11 12:04',
    date: '2015',
    icon: 'screen',
    title: 'Node.js developer',
    company: 'BotScanner',
    employment: 'freelance',
    description: [
      `Bot scanner - external browser plugin which helps detect of each site online user is that bot or not (there are using a few browser metrics and user behavior to detect abnormal for human behavior and block by rules unnecessary activities for that user to avoid server advantage).`
    ],
    tehnologies: ['ExpressJS', 'MySQL', 'MongoDB', 'jQuery', 'NGINX proxies'],
    responsibilities: [
      'Analysis, design and development of user analysis solutions',
      'Implementation algorithm for bot detections',
      'Front-end and back-end part of the project'
    ]
  },
  {
    dateTime: '2013-04-11 12:04',
    date: '2015',
    icon: 'screen',
    title: 'Node.js developer',
    company: 'SocialBakers',
    employment: 'freelance',
    description: [
      `SocialBakers - global scrapping solution that can help companies and users to control social activities on Facebook, Twitter, Youtube and other social networks. The main idea - it’s a scrapping of all metrics for each tracked account to collect subscribers count, likes, velocities and other metrics. Building ratings of social pages for each country and any area (like politics, fashion industry, traveling and so on).`
    ],
    tehnologies: ['NodeJS', 'CentOS', 'Docker', 'Mocha/Chai'],
    responsibilities: [
      'Create from zero web crawling system, based on clustering (parsing Twitter, Facebook, YouTube, Instagram and other social networks)',
      'Analysis and classification for parsed data',
      'Third Parties API integrations',
      'DB support and system administration'
    ]
  }
];

export default history;
