import Home from '../screens/Home';
import Page404 from '../screens/Page404';
import PageReact from '../screens/Page-react';
import PageReact2 from '../screens/Page-react2';

// ADMIN AREA

const Routes = [
  {
    path: '/:language?',
    element: Home,
    exact: true
  },
  {
    path: '/:language?/react/',
    element: PageReact,
    exact: true
  },
  {
    path: '/:language?/react-development/',
    element: PageReact2,
    exact: true
  },
  {
    exact: false,
    path: '*',
    element: Page404
  }
];

export default Routes;
