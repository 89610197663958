const translate = {
  _HOME_CONTACT: 'Skontaktuj się ze mną',
  _SEO_TITLE1: 'Strona z osobistym CV, Kylypko Grygorii',
  _SEO_TITLE2:
    'Świadczę pełny cykl usług deweloperskich Node.js/React.js, aby pomóc firmom i startupom w tworzeniu ich marzeń.',
  _SEO_KEYWORDS: `reaguj na rozwój, zatrudnij programistę nodejs, programistę nodejs, lidera zespołu nodejs`,
  _HOME_TITLE1: `Kyłypko Grygorii`,
  _HOME_TITLE2: `Lider zespołu/technologii, starszy programista Full Stack`,
  _HOME_TEXT1: `Jestem inżynierem Fullstack z ponad 15-letnim doświadczeniem w tworzeniu innowacyjnych rozwiązań i dostarczaniu wysokiej jakości oprogramowania. Moja wiedza specjalistyczna polega na projektowaniu i opracowywaniu wysokowydajnych produktów oprogramowania i jestem głęboko zaangażowany w wprowadzanie innowacji, osiąganie celów firmy i utrzymywanie solidnej wiedzy na temat pojawiających się technologii. Mój zestaw umiejętności obejmuje szeroką biegłość w Node.js, React.js, JavaScript, MongoDB i PostgreSQL. Staram się solidnie rozumieć struktury danych, co umożliwi każdemu projektowi skuteczne radzenie sobie ze złożonymi wyzwaniami.`,
  _HOME_LIST: [
    `Ponad 9 lat doświadczenia w komercyjnym tworzeniu rozwiązań programowych ze szczególnym naciskiem na Node.js (część backendowa).`,
    `Doświadczenia w zakresie rosnącej odpowiedzialności w projektowaniu oprogramowania, analizie systemów, rozwoju, strategiach wdrażania projektów i opracowywaniu projektów w pełnym cyklu życia.`,
    `Silny zestaw umiejętności frontendowych i backendowych (Node.js, React.js, JavaScript, MongoDB, PostgreSQL itp.)`,
    `Duże praktyczne doświadczenie w projektowaniu architektury systemów (Videostreaming, sieć iOT, billingi bankowe i integracje z firmami zewnętrznymi).`,
    `Praktyczne doświadczenie na wszystkich etapach prac nad rozwojem systemu, w tym definiowanie wymagań, projektowanie, architektura, testowanie i wsparcie.`,
    `Doświadczenie w pisaniu kodu, debugowaniu, naprawianiu błędów, analizie wymagań, wdrażaniu funkcji, etapach integracji i historii użytkowników (strona UX/UI).`,
    `Dobre logiczne myślenie, szybkie nabywanie nowych umiejętności, samodzielność, samokształcenie, wysoki poziom odpowiedzialności.`,
    `Odpowiedzialny, pracowity, zorientowany na wyniki, kreatywny i komunikatywny, gracz zespołowy.`,
    `Dobra dbałość o szczegóły. Szybko się uczę.`,
    `Wolę pracować z metodologią TDD i próbować zredukować wszelkie problemy produkcyjne do 0`,
    `Doskonałe umiejętności rozwiązywania problemów, komunikacji i zarządzania czasem.`,
    `Umiejętność oceny technologii i analizy wymagań biznesowych.`
  ]
};

export default translate;
